import { Link } from "gatsby"
import React, { useContext } from "react"
import moon from "../images/icons/moon.svg"
import sun from "../images/icons/sun.svg"
import { ThemeContext } from "../store/theme"

const styles = {
  Navbar: {
    margin: `0 auto`,
    maxWidth: 960,
    padding: `1.45rem 1.0875rem`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}

const Navbar = () => {
  const theme = useContext(ThemeContext)
  return (
    <div style={styles.Navbar} className="navbar__container">
      <h2 className="navbar__container__mainLink">
        <Link to="/" className="navbar__container__links__link">
          J.CELEBI
        </Link>
      </h2>
      <div className="navbar__container">
        <div className="navbar__container__links">
          <Link to="/#experience" className="navbar__container__links__link">
            EXPERIENCE
          </Link>
          <Link
            to="/#about"
            style={styles.Link}
            className="navbar__container__links__link"
          >
            A PROPOS
          </Link>
          <Link
            to="/#contact"
            style={styles.Link}
            className="navbar__container__links__link"
          >
            CONTACT
          </Link>
        </div>
        {theme?.theme !== "dark" ? (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={""}
            onClick={theme?.setTheme()}
            style={{
              backgroundColor: "unset",
              border: "none",
              display: "flex",
              cursor: "pointer"
            }}
          >
            <img
              style={{
                margin: "0",
                filter: "invert(1)",
                width: "20px"
              }}
              src={sun}
              alt="sun"
            />
          </div>
        ) : (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={""}
            onClick={theme?.setTheme()}
            style={{
              backgroundColor: "unset",
              border: "none",
              display: "flex",
              cursor: "pointer"
            }}
          >
            <img
              className="light"
              style={{
                margin: "0",
                width: "20px"
              }}
              src={moon}
              alt="light"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar
