import React from "react"

const Footer = () => {
  const styles = {
    Footer: {
      height: "120px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "auto",
      backgroundColor: "var(--primary-color)",
      color: "var(--secondary-color)",
      width: "100%",
    },
  }

  return (
    <footer style={styles.Footer}>
      <div
        style={{
          margin: "0px auto",
          maxWidth: "960px",
          padding: "1.45rem 1.0875rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Jerome CELEBI · © {new Date().getFullYear()} All rights reserved
      </div>
    </footer>
  )
}

export default Footer
