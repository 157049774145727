import React from "react"
import Navbar from "./navbar"

const Header = () => (
  <header
    className=""
    style={{
      width: "100%",
      background: `var(--primary-color)`,
    }}
  >
    <Navbar />
  </header>
)

export default Header
