/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import SEO from "./seo"
import "./layout.scss"
import { ThemeContext } from "../store/theme"

const Layout = ({ children }) => {
  const theme = useContext(ThemeContext)
  const styles = {
    Layout: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      minHeight: "100vh",
      position: "relative",
      color: "var(--primary-color)",
    },
  }

  return (
    <div
      className={`layout ${theme?.theme === "dark" ? theme.theme : ""}`}
      style={styles.Layout}
    >
      <SEO title="" />
      <Header />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
      </div>

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
